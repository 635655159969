/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { getByPath, sortData } from 'utils';

import {
  Widget,
  getSortConfig as getWidgetSortConfig
} from 'components/ui/widget';
import SearchContextProvider, {
  SearchContext
} from 'components/ui/search/searchContext';
import SearchBar from 'components/ui/search';
import { filterComments } from 'utils/comment';
import styles from './array.style';

export const ArrayView = React.memo(({ classes, view, data }) => {
  const { itemsPath, itemsWidget, defaultSort } = view;
  const { state: searchState } = useContext(SearchContext) || {};
  const items = getByPath(data, itemsPath);

  if (!Array.isArray(items)) {
    return null;
  }

  let itemsToDisplay = items;
  if (defaultSort) {
    const { direction } = defaultSort;
    const defaultSortConfig = getWidgetSortConfig(itemsWidget || {});
    itemsToDisplay = sortData(itemsToDisplay, defaultSortConfig, direction);
  }
  if (searchState?.string) {
    itemsToDisplay = filterComments(itemsToDisplay, searchState?.string);
  }
  return view.searchBar ? (
    <div className={classes.array}>
      <div className={classes.searchBarContainer}>
        <SearchBar config={view.searchBar} />
      </div>
      {itemsToDisplay.map((item, index) => (
        <Widget key={index} widget={itemsWidget} data={item} />
      ))}
      {itemsToDisplay.length === 0 && <div className={classes.noResults} />}
    </div>
  ) : (
    <div className={classes.array}>
      {itemsToDisplay.map((item, index) => (
        <Widget key={index} widget={itemsWidget} data={item} />
      ))}
    </div>
  );
});
const ArrayComponent = ({ children, ...props }) => {
  const searchBarConfig = props.view.searchBar || undefined;
  if (searchBarConfig) {
    return (
      <SearchContextProvider config={searchBarConfig}>
        <ArrayView {...props}>{children}</ArrayView>
      </SearchContextProvider>
    );
  }
  return <ArrayView {...props}>{children}</ArrayView>;
};

ArrayView.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};
export default {
  component: injectSheet(styles)(ArrayComponent)
};
