import React, { useState } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import { getByPath } from 'utils/widgets';

import styles from './widget.style';
import { getView } from './views';
import {
  LinkWrapper,
  ModalWrapper,
  TooltipWrapper,
  TrayWrapper,
  InlineStyleWrapper
} from './wrappers';

import {
  withSelectableContext,
  enhanceSelectableView
} from './contexts/selectableItems';

const hasWrapper = (data, wrapper) => {
  if (!wrapper) return false;
  const { conditionPath } = wrapper;
  return !conditionPath || !!getByPath(data, conditionPath);
};
const getViewComponent = (view, data, update) => {
  let ViewComponent = getView(view).component;
  if (view.selectable || view.selectableItems) {
    ViewComponent = withSelectableContext(ViewComponent);
  }
  return <ViewComponent view={view} data={data} update={update} />;
};

/* widget component */
const WidgetImpl = React.memo(
  ({ classes, widget, data, update, className, disableWrappers }) => {
    if (!widget) return null;
    // get view
    const view = widget.view?.selectableItems
      ? enhanceSelectableView(widget.view)
      : widget.view;

    // wrappers
    const { link, modal, tooltip, tray, inlineStyle } = widget;

    // provide wrapper sub-components with a way to tell the widget to keep focused
    const [isFocus, setFocus] = useState(false);

    // disable wrappers when specified (eg when editing layout)
    const hasLink = !disableWrappers && hasWrapper(data, link);
    const hasModal = !disableWrappers && hasWrapper(data, modal);
    const hasTooltip = !disableWrappers && hasWrapper(data, tooltip);
    const hasTray = !disableWrappers && hasWrapper(data, tray);
    const hasInlineStyle = !disableWrappers && hasWrapper(data, inlineStyle);
    const isHoverable = hasLink || hasModal || hasTray;

    // get the view component, ie what is displayed inside the widget
    const viewComponent = getViewComponent(view || {}, data, update, link);

    let widgetComponent = (
      <div
        className={classnames(className, classes.widgetContainer)}
        data-is-hoverable={isHoverable} // the widget is styled 'hoverable' if there is at least one wrapper
        data-is-focus={isFocus}
      >
        {viewComponent}
      </div>
    );

    // wrap with link
    if (hasLink) {
      const { rootUrl, urlPath, openInNewTab } = link;
      const urlData = getByPath(data, urlPath);
      widgetComponent = (
        <LinkWrapper url={`${rootUrl}${urlData}`} openInNewTab={openInNewTab}>
          {widgetComponent}
        </LinkWrapper>
      );
    }

    // wrap with modal
    if (hasModal) {
      const {
        title: modalTitle = {},
        body: modalBody = {},
        large,
        fullscreen
      } = modal;

      // Modal title
      // let the user choose between a simple text title or a more complete widget setup
      const modalTitleWidget =
        typeof modalTitle === 'object' ? (
          <Widget widget={modalTitle} data={data} update={update} />
        ) : (
          <div>{modalTitle}</div>
        );

      // Modal body
      const modalBodyWidget = (
        <Widget widget={modalBody} data={data} update={update} />
      );

      widgetComponent = (
        <ModalWrapper
          title={modalTitleWidget}
          body={modalBodyWidget}
          setClientFocus={setFocus}
          large={large}
          fullscreen={fullscreen}
        >
          {widgetComponent}
        </ModalWrapper>
      );
    }

    // wrap with tooltip
    if (hasTooltip) {
      const {
        title: tooltipTitle,
        body: tooltipBody = {},
        position,
        delay,
        withArrow
      } = tooltip;

      const getContent = item =>
        typeof item === 'object' ? (
          <Widget widget={item} data={data} update={update} />
        ) : (
          <div>{item}</div>
        );

      widgetComponent = (
        <TooltipWrapper
          title={getContent(tooltipTitle)}
          body={getContent(tooltipBody)}
          position={position}
          delay={delay}
          withArrow={withArrow}
        >
          {widgetComponent}
        </TooltipWrapper>
      );
    }

    // wrap with tray
    if (hasTray) {
      const {
        title: trayTitle = {},
        body: trayBody = {},
        width,
        collapsedWidth,
        resizable,
        minWidth
      } = tray;

      // Tray title
      // let the user choose between a simple text title or a more complete widget setup
      const trayTitleWidget =
        typeof trayTitle === 'object' ? (
          <Widget widget={trayTitle} data={data} />
        ) : (
          trayTitle
        );

      // Tray body
      const trayBodyWidget = (
        <Widget widget={trayBody} data={data} update={update} />
      );

      widgetComponent = (
        <TrayWrapper
          title={trayTitleWidget}
          body={trayBodyWidget}
          setClientFocus={setFocus}
          width={width}
          collapsedWidth={collapsedWidth}
          resizable={resizable}
          minWidth={minWidth}
        >
          {widgetComponent}
        </TrayWrapper>
      );
    }

    // wrap with inline style
    if (hasInlineStyle) {
      widgetComponent = (
        <InlineStyleWrapper data={data} rules={inlineStyle}>
          {widgetComponent}
        </InlineStyleWrapper>
      );
    }

    return widgetComponent;
  }
);
WidgetImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  widget: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  update: PropTypes.object,
  className: PropTypes.string,
  disableWrappers: PropTypes.bool
};
WidgetImpl.defaultProps = {
  update: null,
  className: '',
  disableWrappers: false
};

/* interfaces */
const getViewConfig = widget => widget.view || {};
// note: so far only the view provides access to the default sort and filter configs
// later, views could delegate the choice to wrappers as well if they can't decide (eg icons...)
export const getSortConfig = widget => {
  const view = getViewConfig(widget);
  return getView(view).getSortConfig(view);
};
export const getFilterConfig = widget => {
  const view = getViewConfig(widget);
  return getView(view).getFilterConfig(view);
};
export const getDefaultWidth = widget => {
  const view = getViewConfig(widget);
  return getView(view).getDefaultWidth(view);
};
export const getStringifyFunction = (widget, workflowContext) => {
  const view = getViewConfig(widget);
  const viewStringifyFunction = getView(view).getStringifyFunction;
  // if the view does not specify any stringify function just output empty string
  return viewStringifyFunction
    ? viewStringifyFunction(view, workflowContext)
    : () => '';
};
export const getEditedPath = widget => {
  // get the path of the data linked to the editor if configured
  const view = getViewConfig(widget);
  const viewEditedPathFunction = getView(view).getEditedPath;
  return viewEditedPathFunction ? viewEditedPathFunction(view) : null;
};
export const getAggregationConfig = widget => {
  let view = getViewConfig(widget);
  const { aggregation } = widget;
  if (aggregation?.aggregationValue) {
    const {
      aggregationKey,
      aggregationValue,
      aggregationFunction
    } = aggregation;
    view = {
      ...view,
      aggregationKey,
      aggregationValue,
      aggregationFunction
    };
  }
  const getAggregationConfigFn = getView(view).getAggregationConfig;
  if (!getAggregationConfigFn) return null; // by default no aggregation
  return getAggregationConfigFn(view);
};
export const Widget = injectSheet(styles)(WidgetImpl);
