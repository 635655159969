export default theme => ({
  commentFeed: {
    padding: '20px',
    overflow: 'auto',
    height: '100%'
  },
  noComments: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
    color: theme.grey3
  },
  comment: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
    borderLeft: '5px solid transparent'
  },
  commentPatch: {
    top: 0,
    left: 0,
    width: '5px',
    height: '100%',
    position: 'absolute',
    backgroundColor: '#30CECA'
  },
  commentList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  commentActions: {
    display: 'flex',
    gap: '10px',
    '& > span': {
      cursor: 'pointer',

      '&:hover': {
        color: theme.indigo3
      }
    }
  }
});
