import { useEffect, useRef, useState } from 'react';

import injectSheet, { ClassNameMap } from 'react-jss';
import { Pushbutton, RichEditor } from '@stratumn/atomic';

import { compose } from 'react-apollo';
import { withUser } from 'contexts';
import styles from './addComment.style';
import { ICommentData } from '../..';

interface AddCommentProps {
  classes: ClassNameMap<string>;
  // eslint-disable-next-line no-unused-vars
  data: ICommentData[];
  // eslint-disable-next-line no-unused-vars
  setData: (_comment: ICommentData[]) => void;
  isCommentWithSubject: boolean;
  user: any;
  onCancel?: () => void;
  isEditing?: boolean;
}

const checkIsEmptyComment = comment => {
  if (!comment) return true;
  const commentArray = JSON.parse(comment);
  return commentArray.every(item => item.isEmpty === true);
};

const AddComment = ({
  classes,
  data,
  setData,
  isCommentWithSubject,
  user,
  onCancel,
  isEditing = false
}: AddCommentProps) => {
  const richEditorRef = useRef();
  const [inputStr, setInputStr] = useState('');

  useEffect(() => {
    // Si on est en mode édition et qu'il y a un commentaire, on initialise `inputStr`
    if (data?.length === 1 && isEditing) {
      setInputStr(data[0].comment);
    }
  }, [data, isEditing]);

  const handleChange = (richValue: string) => {
    setInputStr(richValue);
  };

  if (user.loading || !user) {
    return null;
  }

  const { me } = user;

  const addOrEditComment = () => {
    if (data?.length === 1 && isEditing) {
      const updatedComment = {
        ...data[0],
        comment: inputStr
      };
      setData([updatedComment]);
    } else {
      const newComment = {
        comment: inputStr,
        date: new Date().toISOString(),
        user: {
          accountId: me.rowId,
          name: me.name,
          avatar: me.avatar
        }
      };
      const newData = data ? [...data, newComment] : [newComment];
      setData(newData);
    }
    // Clear editor after action
    if (richEditorRef.current) richEditorRef.current.clearEditor();
    setInputStr('');
  };

  const handleCancel = () => {
    // Réinitialise l'éditeur et invoque onCancel pour fermer le mode édition
    setInputStr('');
    if (richEditorRef.current) richEditorRef.current.clearEditor();
    if (onCancel) onCancel();
  };

  return (
    <div className={classes.addComment}>
      <RichEditor
        initialValue={inputStr}
        withSubject={isCommentWithSubject}
        onChange={handleChange}
        autoFocus
        withMentions
        ref={richEditorRef}
      />
      <div className={classes.submitButton}>
        <Pushbutton
          primary
          onClick={addOrEditComment}
          dataCy="addComment-button"
          disabled={checkIsEmptyComment(inputStr)}
        >
          {isEditing ? 'Save' : 'Add Comment'}
        </Pushbutton>
        {isEditing && (
          <Pushbutton onClick={handleCancel} dataCy="cancelComment-button">
            Cancel
          </Pushbutton>
        )}
      </div>
    </div>
  );
};

export default compose(withUser, injectSheet(styles))(AddComment);
