import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import gql from 'graphql-tag';
import pathToRegexp from 'path-to-regexp';

import { REFERENCES, REFERENCED_BY } from 'constant/chainscript';
import { ROUTE_INSPECT_TRACE, ROUTE_WORKFLOW_OVERVIEW } from 'constant/routes';
import { ReferenceLink } from '../references.types';
import styles from './reference.style';

type ReferenceProps = {
  classes: any;
  link: ReferenceLink;
  traceId: string;
  workflowId: string;
  refCount: number;
  referenceType: typeof REFERENCES | typeof REFERENCED_BY;
};

export const Reference = ({
  classes,
  link,
  traceId,
  refCount,
  workflowId,
  referenceType
}: ReferenceProps) => {
  const hrefTrace = `${pathToRegexp.compile(ROUTE_INSPECT_TRACE)({
    id: link.traceId
  })}`;

  const hrefTraceWithLink = link.linkHash
    ? `${hrefTrace}/${link.linkHash}`
    : hrefTrace;

  const hrefWorkflow = `${pathToRegexp.compile(ROUTE_WORKFLOW_OVERVIEW)({
    id: link.workflow.rowId
  })}`;

  if (link.traceId === traceId) {
    return (
      <span className={classes.reference}>
        {getPrefix(refCount, referenceType)}{' '}
        <ActionTitle
          hrefTrace={hrefTraceWithLink}
          actionTitle={link.action.title}
          referenceLinkHeight={link.height}
        />{' '}
        of this Trace.
      </span>
    );
  }

  if (link.workflow.rowId === workflowId) {
    return (
      <span className={classes.reference}>
        {getPrefix(refCount, referenceType)}{' '}
        <ActionTitle
          hrefTrace={hrefTraceWithLink}
          actionTitle={link.action.title}
          referenceLinkHeight={link.height}
        />{' '}
        in the Trace{' '}
        <TraceName hrefTrace={hrefTrace} traceName={link.trace.name} /> in this
        workflow.
      </span>
    );
  }

  return (
    <span className={classes.reference}>
      {getPrefix(refCount, referenceType)}{' '}
      <ActionTitle
        hrefTrace={hrefTraceWithLink}
        actionTitle={link.action.title}
        referenceLinkHeight={link.height}
      />{' '}
      in the Trace{' '}
      <TraceName hrefTrace={hrefTrace} traceName={link.trace.name} /> of the{' '}
      <WorkflowName
        hrefWorkflow={hrefWorkflow}
        workflowName={link.workflow.name}
      />{' '}
      workflow.
    </span>
  );
};

const getPrefix = (
  refCount: ReferenceProps['refCount'],
  referenceType: ReferenceProps['referenceType']
) => {
  let prefixText = '';
  if (refCount === 1) prefixText += 'this action ';

  prefixText +=
    referenceType === REFERENCES ? 'references' : 'is referenced by';
  return prefixText.charAt(0).toUpperCase() + prefixText.slice(1);
};

type ActionTitleProps = {
  hrefTrace: string;
  actionTitle: ReferenceLink['action']['title'];
  referenceLinkHeight: ReferenceLink['height'];
};

const ActionTitle = ({
  hrefTrace,
  actionTitle,
  referenceLinkHeight
}: ActionTitleProps) => (
  <a
    href={`${hrefTrace}?height=${referenceLinkHeight}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {actionTitle}
  </a>
);

type TraceNameProps = {
  hrefTrace: string;
  traceName: ReferenceLink['trace']['name'];
};

const TraceName = ({ hrefTrace, traceName }: TraceNameProps) => (
  <a href={hrefTrace} target="_blank" rel="noopener noreferrer">
    {traceName}
  </a>
);

type WorkflowNameProps = {
  hrefWorkflow: string;
  workflowName: ReferenceLink['workflow']['name'];
};
const WorkflowName = ({ hrefWorkflow, workflowName }: WorkflowNameProps) => (
  <a href={hrefWorkflow} target="_blank" rel="noopener noreferrer">
    {workflowName}
  </a>
);

Reference.propTypes = {
  classes: PropTypes.object.isRequired,
  link: PropTypes.object.isRequired,
  traceId: PropTypes.string.isRequired,
  workflowId: PropTypes.string.isRequired,
  refCount: PropTypes.number.isRequired,
  referenceType: PropTypes.oneOf([REFERENCES, REFERENCED_BY]).isRequired
};

export default injectSheet(styles)(Reference);

export const fragment = gql`
  fragment ReferenceFragment on Link {
    linkHash
    height
    actionKey
    traceId
    action {
      title
    }
    trace {
      name
    }
    workflow {
      rowId
      name
    }
  }
`;
