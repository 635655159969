import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { getByPath } from 'utils';
import merge from 'lodash.merge';

import injectSheet from 'react-jss';
import DynamicIcon from 'components/ui/dynamicIcon';
import styles from './commentFeed.style';
import CommentView, { PATHS } from '../comment';
import AddComment from './editors/addComment';

export interface ICommentData {
  date: string;
  user: {
    name: string;
    avatar: string;
    accountId: string;
  };
  group?: string;
  action?: string;
  comment: any;
  groupId?: string;
  groupLabel?: string;
}

interface ICommentFeedViewProps {
  classes: any;
  view: { path: string; editor: any };
  data: any;
  update: any;
}

const viewPath = {
  namePath: PATHS.NAME,
  avatarPath: PATHS.AVATAR,
  groupPath: PATHS.GROUP,
  datePath: PATHS.DATE,
  actionPath: PATHS.ACTION,
  commentPath: PATHS.COMMENT
};

const CommentComponent = CommentView.component;

const isSameArray = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export const CommentFeedView: FC<ICommentFeedViewProps> = memo(
  ({ classes, view, data, update }) => {
    const { path, editor } = view;
    const { patch, onChange, setIsPatched } = update || {};

    const comments = getByPath(data, path) as ICommentData[];
    const dataWithPatchApplied = useMemo(() => merge({}, data, patch), [
      data,
      patch
    ]);
    const displayData = getByPath(dataWithPatchApplied, path) || undefined;

    const [updatedComment, setUpdatedComment] = useState<ICommentData[]>(
      displayData
    );
    const [editingComments, setEditingComments] = useState<{
      [index: number]: ICommentData;
    }>({});

    const handleChange = useCallback(
      value => {
        const isSameAsData =
          isSameArray(value, comments) ||
          (comments === undefined && value.length === 0);
        if (onChange) {
          onChange({ path, value: isSameAsData ? undefined : value });
        }
      },
      [path, onChange]
    );

    const handleRemove = index => {
      const newComments = updatedComment.filter((_, i) => i !== index);
      setUpdatedComment(newComments);
    };

    const handleEdit = (index: number) => {
      setEditingComments(prevEditingComments => ({
        ...prevEditingComments,
        [index]: updatedComment[index]
      }));
    };

    const handleCancelEdit = (index: number) => {
      setUpdatedComment(prevComments => {
        const revertedComments = [...prevComments];
        revertedComments[index] = editingComments[index];
        return revertedComments;
      });
      setEditingComments(prevEditingComments => {
        const updatedEditingComments = { ...prevEditingComments };
        delete updatedEditingComments[index];
        return updatedEditingComments;
      });
    };

    const handleSaveEdit = (index: number, editedComment: any) => {
      setUpdatedComment(prevComments => {
        const updatedComments = [...prevComments];
        updatedComments[index] = {
          ...updatedComments[index],
          comment: editedComment
        };
        return updatedComments;
      });
      setEditingComments(prevEditingComments => {
        const updatedEditingComments = { ...prevEditingComments };
        delete updatedEditingComments[index];
        return updatedEditingComments;
      });
    };

    useEffect(() => {
      if (setIsPatched) setIsPatched(!isSameArray(displayData, comments));
    }, [comments, displayData]);

    useEffect(() => {
      handleChange(updatedComment);
    }, [updatedComment]);

    return (
      <div className={classes.commentFeed}>
        <div className={classes.commentList}>
          {(displayData?.length === 0 || !displayData) && (
            <div className={classes.noComments}>Comments is empty</div>
          )}
          {displayData?.map((cmmnt, index) => (
            <div key={index} className={classes.comment}>
              {editingComments[index] ? (
                <>
                  <AddComment
                    data={[cmmnt]}
                    setData={newComment =>
                      handleSaveEdit(index, newComment[0].comment)
                    }
                    isCommentWithSubject
                    user={{ me: cmmnt.user }}
                    onCancel={() => handleCancelEdit(index)}
                    isEditing
                  />
                </>
              ) : (
                <>
                  {!comments?.[index] && (
                    <div className={classes.commentPatch} />
                  )}
                  <CommentComponent key={index} data={cmmnt} view={viewPath} />
                  {!comments?.[index] && onChange && (
                    <div className={classes.commentActions}>
                      <span onClick={() => handleEdit(index)}>
                        <DynamicIcon size={20} icon="Pen" />
                      </span>
                      <span onClick={() => handleRemove(index)}>
                        <DynamicIcon size={20} icon="Trash" />
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
        {editor?.type === 'addComment' && onChange && (
          <AddComment
            onChange={handleChange}
            editor={editor}
            isCommentWithSubject
            data={updatedComment}
            setData={setUpdatedComment}
          />
        )}
      </div>
    );
  }
);

// get edited path
// returns path if editor is set, null otherwise
const getEditedPath = view => {
  const { path, editor } = view;
  return editor ? path : null;
};

export default {
  component: injectSheet(styles)(CommentFeedView),
  getEditedPath
};
