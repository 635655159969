import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { Pushbutton, Tray } from '@stratumn/atomic';

import { TRAY_PORTAL_RIGHT } from 'constant/htmlIds';

import styles from './trayWrapper.style';

export const TrayWrapper = React.memo(
  ({
    classes,
    children,
    title,
    body,
    width, // width of the tray when uncollapsed
    collapsedWidth, // width of the tray when collapsed
    resizable, // whether the tray is resizable when uncollapsed
    minWidth, // minimun width if the tray is resizable
    setClientFocus
  }) => {
    const [showTray, setShowTray] = useState(false);
    const openTray = useCallback(() => {
      setShowTray(true);
      setClientFocus(true);
    }, []);
    const closeTray = useCallback(() => {
      setShowTray(false);
      setClientFocus(false);
    }, []);

    // Marked as updated, if the widget wrap by the tray has an update
    // If we don't do that, the update mark will only be displayed when the tray is opened
    if (body.props) {
      const { widget, update = {} } = body.props;
      const viewPath = widget.view.path;

      if (update) {
        const { patch, setIsPatched } = update;

        if (patch && setIsPatched && viewPath in patch) {
          setIsPatched(true);
        }
      }
    }

    return (
      <>
        <div
          onClick={openTray}
          className={classes.trayWrapper}
          data-cy="open-tray"
        >
          {children}
        </div>
        {showTray && (
          <Tray
            portalEl={document.getElementById(TRAY_PORTAL_RIGHT)}
            title={title}
            width={width}
            collapsedWidth={collapsedWidth}
            resizable={resizable}
            minWidth={minWidth}
            onClose={closeTray}
          >
            <div className={classes.trayWrapperContent}>
              <div className={classes.trayWrapperBody}>{body}</div>
              <div className={classes.trayWrapperFooter}>
                <div className={classes.trayWrapperActions}>
                  <Pushbutton onClick={closeTray} disabled={false}>
                    CLOSE
                  </Pushbutton>
                </div>
              </div>
            </div>
          </Tray>
        )}
      </>
    );
  }
);

TrayWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  width: PropTypes.number,
  collapsedWidth: PropTypes.number,
  resizable: PropTypes.bool,
  minWidth: PropTypes.number,
  setClientFocus: PropTypes.func
};

TrayWrapper.defaultProps = {
  width: 450,
  collapsedWidth: undefined,
  resizable: true,
  minWidth: 450,
  setClientFocus: () => {}
};

export default injectSheet(styles)(TrayWrapper);
