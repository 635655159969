import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Path from 'path-to-regexp';
import { ROUTE_INSPECT_TRACE_LINK } from 'constant/routes';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { RichEditor, Icon } from '@stratumn/atomic';
import { Paperclip } from '@stratumn/icons';
import { TraceInspectorContext } from 'components/traceInspector/context';
import { SearchContext } from 'components/ui/search/searchContext';
import { getByPath } from 'utils/widgets';
import AnswerCard from 'components/ui/answerCard';
import { formatDateWithoutSeconds, isJSON, checkCommentExists } from 'utils';
import styles from './comment.style';

export const PATHS = {
  NAME: 'user.name',
  AVATAR: 'user.avatar',
  GROUP: 'group',
  DATE: 'date',
  ACTION: 'action',
  COMMENT: 'comment',
  HASH: 'linkHash',
  HEIGHT: 'height',
  FILES: 'files',
  ANSWERS: 'answers'
};

export const CommentView = React.memo(({ classes, view, data }) => {
  const traceInspectorContext = useContext(TraceInspectorContext);
  const { traceId, currentHash } =
    traceInspectorContext !== null && traceInspectorContext;
  const { state: searchState } = useContext(SearchContext) || {};
  const {
    namePath = PATHS.NAME,
    avatarPath = PATHS.AVATAR,
    groupPath = PATHS.GROUP,
    datePath = PATHS.DATE,
    actionPath = PATHS.ACTION,
    commentPath = PATHS.COMMENT,
    linkHashPath = PATHS.HASH,
    heightPath = PATHS.HEIGHT,
    filesPath = PATHS.FILES,
    answersPath = PATHS.ANSWERS,
    path
  } = view;

  const name = getByPath(data, namePath);
  const avatar = getByPath(data, avatarPath);
  const group = getByPath(data, groupPath);
  const date = getByPath(data, datePath);
  const action = getByPath(data, actionPath);
  const hash = getByPath(data, linkHashPath);
  const height = getByPath(data, heightPath);
  const files = getByPath(data, filesPath);
  const commentPathValue = commentPath || path;
  const comment = getByPath(data, commentPathValue);
  const answers = getByPath(data, answersPath);

  const isCommentWithSubject = () => {
    const commentArray = isJSON(comment) ? JSON.parse(comment) : [];
    return commentArray.some(c => c?.type === 'subject');
  };
  const [showAnswers, setShowAnswers] = useState(false);
  useEffect(() => {
    const hasMatchingAnswer = searchState?.string
      ? answers?.some(answer =>
          answer.data.answer.includes(searchState?.string)
        )
      : false;
    setShowAnswers(hasMatchingAnswer);
  }, [searchState?.string, answers]);

  return (
    data &&
    checkCommentExists(data[commentPath]) && (
      <div className={classes.comment}>
        <div
          className={classes.commentAvatar}
          style={{
            backgroundImage: avatar ? `url(${avatar})` : null
          }}
        />
        <div className={classes.commentContainer}>
          <ul>
            {data?.user && (
              <li className={classes.userId}>
                <div className={classes.userName}>{name}</div>
                {group && <div className={classes.userGroup}>{group}</div>}
              </li>
            )}
            {date && (
              <li>
                {traceId && hash ? (
                  <Link
                    to={Path.compile(ROUTE_INSPECT_TRACE_LINK)({
                      id: traceId,
                      linkid: hash
                    })}
                    className={classNames({
                      [classes.link]: true,
                      [classes.inactiveLink]: currentHash === hash
                    })}
                  >
                    <strong className={classes.action}>
                      {height} - {action}
                    </strong>{' '}
                    <span className={classes.date}>
                      Commented on {formatDateWithoutSeconds(date)}
                    </span>
                  </Link>
                ) : (
                  <div>
                    <span className={classes.date}>
                      Commented on {formatDateWithoutSeconds(date)}
                    </span>
                  </div>
                )}
              </li>
            )}
            <li>
              <div className={classes.commentRoot}>
                <RichEditor
                  initialValue={comment}
                  withSubject={isCommentWithSubject()}
                  readOnly
                  searchValue={searchState?.string}
                />
              </div>
            </li>
            {files?.length > 0 && (
              <li className={classes.files}>
                <Paperclip className={classes.paperClip} />
                {`Attachment${files?.length > 1 ? `s` : ``} (${
                  data?.files?.length
                })`}
              </li>
            )}
            {answers?.length > 0 && (
              <button
                className={classes.answersButton}
                onClick={() => setShowAnswers(!showAnswers)}
              >
                {`${answers?.length} Answer${answers.length > 1 ? 's' : ''}`}
                <div
                  className={classes.answersButtonIcon}
                  data-is-collapsed={!showAnswers}
                >
                  <Icon name="ArrowDownFill" />
                </div>
              </button>
            )}
            {showAnswers &&
              answers?.length > 0 &&
              answers
                ?.sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
                .map((answer, i) => (
                  <AnswerCard
                    data={answer}
                    key={i}
                    searchValue={searchState?.string}
                  />
                ))}
          </ul>
        </div>
      </div>
    )
  );
});

/** Sorts defaults to text on `datePath` (text is ok assuming ISO dates) */
const getSortConfig = view => ({
  type: 'text',
  path: view.datePath || PATHS.DATE
});

/** Filters defaults to text search on comment content */
const getFilterConfig = view => ({
  type: 'text',
  path: view.commentPath || PATHS.COMMENT
});

/** Gets the default width which is `'large'` */
const getDefaultWidth = () => 'large';

/** Stringifies data at commentPath */
const getStringifyFunction = view => {
  const { commentPath = PATHS.COMMENT } = view;
  return data => getByPath(data, commentPath) || '';
};

CommentView.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default {
  component: injectSheet(styles)(CommentView),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
